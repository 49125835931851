import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

const ModalComponent = ({ isModal, closesModal, children, className }: any) => {
  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModal]);
  return (
    <ReactModal
      isOpen={isModal}
      onRequestClose={closesModal}
      ariaHideApp={false}
      portalClassName={className}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '900px',
          width: '80%',
          padding: '50px',
          display: 'block'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
      }}
    >
      <button type="button" className="modal-close" onClick={closesModal}>
        <span className="close">&#x2715;</span>
      </button>
      {children}
    </ReactModal>
  );
};

export { ModalComponent };
